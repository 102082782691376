import styled from 'styled-components'

export const IndicatorScrollContainer = styled.div<{ isUp: boolean }>`
  font-size: 14px;
  font-family: ${({ theme }) => theme.Fonts.redHat.black};
  position: absolute;
  left: 0;
  bottom: ${({ isUp }) => (isUp ? 'unset' : '55px')};
  top: ${({ isUp }) => (isUp ? '0' : 'unset')};
  cursor: ${({ isUp }) => (isUp ? 'pointer' : 'default')};
  text-align: left;
  display: flex;
  flex-direction: ${({ isUp }) => (isUp ? 'column-reverse' : 'column')};
  p {
    transform: ${({ isUp }) =>
      isUp
        ? 'rotate(-90deg) translate(-65px, -2px)'
        : 'rotate(-90deg) translateY(-2px)'};
    transform-origin: left top;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`

import React, { FunctionComponent } from 'react'
import { t, Trans } from '@lingui/macro'
import { JobsItem, JobsMobileImage } from './JobsSections.style'
import { ButtonRed, MainText, PrimaryTitle, TextRed } from 'styles/Global'
import ListSocials from 'components/atoms/ListSocials/ListSocials'
import Image from 'components/Image'
import JobsDecorations from 'components/molecules/JobsDecorations/JobsDecorations'
import { i18n } from '@lingui/core'
import { I18n } from '@lingui/react'

interface Props {
  images: any
}

const JobsSections: FunctionComponent<Props> = ({ images }) => {
  return (
    <>
      <JobsItem>
        <PrimaryTitle as="h2">
          <Trans id="jobs.item1.title">
            Tu souhaites travailler dans une startup qui mise sur{' '}
            <TextRed>l'humain</TextRed> ?
          </Trans>
        </PrimaryTitle>
        <MainText>
          <Trans id="jobs.item1.text">
            On partage des idées, des rires et des "cafés" autour des projets
            car on est convaincu qu'il faut aimer ce que l'on fait pour obtenir
            ce que l'on veut.
          </Trans>
        </MainText>
        <ButtonRed
          as="a"
          href="https://www.linkedin.com/company/redfabriq/jobs/"
          target="_blank"
        >
          <Trans id="jobs.item1.button">Rejoins-nous</Trans>
        </ButtonRed>
        <JobsMobileImage>
          <Image fluid={images.avatar.fluid} alt={images.avatar.alt} />
        </JobsMobileImage>
        <JobsDecorations images={images.human} variant="human" />
      </JobsItem>
      <JobsItem>
        <PrimaryTitle as="h2">
          <Trans id="jobs.item2.title">
            Tu veux pouvoir créer ta propre <span>startup en interne</span> ?
          </Trans>
        </PrimaryTitle>
        <MainText>
          <Trans id="jobs.item2.text">
            Chez nous, chaque collaborateur est l'entrepreneur de sa propre
            aventure. Si tu as une bonne idée, nous pouvons t'aider à la
            développer !
          </Trans>
        </MainText>
        <ButtonRed
          as="a"
          href="https://www.linkedin.com/company/redfabriq/jobs/"
          target="_blank"
        >
          <Trans id="jobs.item2.button">Rejoins-nous</Trans>
        </ButtonRed>
        <JobsMobileImage>
          <Image fluid={images.avatar.fluid} alt={images.avatar.alt} />
        </JobsMobileImage>
        <JobsDecorations images={images.startup} variant="startup" />
      </JobsItem>
      <JobsItem>
        <PrimaryTitle as="h2">
          <Trans id="jobs.item3.title">
            Tu penses que la <span>technologie</span> est meilleure lorsqu'elle
            nous rassemble ?
          </Trans>
        </PrimaryTitle>
        <MainText>
          <Trans id="jobs.item3.text">
            C'est notre ADN, elle nous unit et fait grandir nos idées. Et on
            aime tellement ça qu'on a notre propre lab R&D pour garder une
            longueur d'avance.
          </Trans>
        </MainText>
        <ButtonRed
          as="a"
          href="https://www.linkedin.com/company/redfabriq/jobs/"
          target="_blank"
        >
          <Trans id="jobs.item3.button">Rejoins-nous</Trans>
        </ButtonRed>
        <JobsMobileImage>
          <Image fluid={images.avatar.fluid} alt={images.avatar.alt} />
        </JobsMobileImage>
        <JobsDecorations images={images.technology} variant="technology" />
      </JobsItem>
      {/*<JobsItem>*/}
      {/*  <I18n>*/}
      {/*    {({ i18n }) => (*/}
      {/*      <PrimaryTitle*/}
      {/*        as="h2"*/}
      {/*        dangerouslySetInnerHTML={{*/}
      {/*          __html: i18n._(t`jobs.item4.title`),*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  </I18n>*/}
      {/*  <MainText>*/}
      {/*    <Trans id="jobs.item4.text">*/}
      {/*      C'est pourquoi chacun est libre d'en faire autant qu'il le souhaite,*/}
      {/*      mais nous sommes persuadés que tu aimeras venir travailler au*/}
      {/*      bureau.*/}
      {/*    </Trans>*/}
      {/*  </MainText>*/}
      {/*  <ButtonRed*/}
      {/*    as="a"*/}
      {/*    href="https://www.linkedin.com/company/redfabriq/jobs/"*/}
      {/*    target="_blank"*/}
      {/*  >*/}
      {/*    <Trans id="jobs.item4.button">Rejoins-nous</Trans>*/}
      {/*  </ButtonRed>*/}
      {/*  <ListSocials />*/}
      {/*  <JobsMobileImage>*/}
      {/*    <Image fluid={images.avatar.fluid} alt={images.avatar.alt} />*/}
      {/*  </JobsMobileImage>*/}
      {/*  <JobsDecorations images={images.telework} variant="telework" />*/}
      {/*</JobsItem>*/}
    </>
  )
}

export default JobsSections

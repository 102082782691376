import styled from 'styled-components'

export const JobsDecorationsItem = styled.div`
  height: auto;
  position: absolute;
`

export const JobsDecorationsHuman = styled.div`
  ${JobsDecorationsItem} {
    width: 50px;
    &:first-child {
      top: 30%;
      right: -50%;
      width: 40px;
    }
    &:nth-child(2) {
      bottom: 20%;
      right: -30%;
    }
    &:nth-child(3) {
      top: 0;
      right: -43%;
    }
    &:nth-child(4) {
      right: -90%;
      bottom: 0;
      width: 40px;
    }
    &:last-child {
      right: -100%;
      bottom: 10%;
      width: 30px;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
      &:first-child {
        right: unset;
        top: unset;
        bottom: 40%;
        left: 25%;
      }
      &:nth-child(2) {
        bottom: 20%;
        right: unset;
        left: 10%;
      }
      &:nth-child(3) {
        right: unset;
        left: 20%;
        top: unset;
        bottom: 55%;
      }
      &:nth-child(4) {
        right: 30%;
      }
      &:last-child {
        right: 25%;
      }
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
      width: 30px;
      &:first-child {
        bottom: 35%;
        left: 20%;
        width: 20px;
      }
      &:nth-child(2) {
        bottom: 15%;
        left: 5%;
      }
      &:nth-child(3) {
        left: 15%;
        bottom: 50%;
        width: 20px;
      }
      &:nth-child(4) {
        right: 30%;
        bottom: 5%;
        width: 30px;
      }
      &:last-child {
        right: 15%;
        bottom: 10%;
        width: 20px;
      }
    }
  }
`

export const JobsDecorationsStartup = styled.div`
  ${JobsDecorationsItem} {
    width: 120px;
    &:first-child {
      top: 40px;
      right: -45%;
    }
    &:nth-child(2) {
      width: 40px;
      bottom: 30%;
      right: -35%;
    }
    &:last-child {
      width: 50px;
      right: -100%;
      top: 40%;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
      width: 80px;
      &:first-child {
        right: unset;
        left: 25%;
        top: 35%;
      }
      &:nth-child(2) {
        right: unset;
        left: 20%;
        bottom: 10%;
      }
      &:last-child {
        right: 30%;
        top: 70%;
      }
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
      width: 60px;
      &:first-child {
        left: 5%;
        top: 45%;
      }
      &:nth-child(2) {
        left: 10%;
        bottom: 15%;
        width: 30px;
      }
      &:last-child {
        width: 30px;
        right: 20%;
        top: 70%;
      }
    }
  }
`

export const JobsDecorationsTech = styled.div`
  ${JobsDecorationsItem} {
    width: 100px;
    &:first-child {
      width: 60px;
      bottom: 25%;
      right: -100%;
    }
    &:last-child {
      top: 70px;
      right: -50%;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
      &:first-child {
        bottom: 15%;
        right: 30%;
      }
      &:last-child {
        top: 35%;
        right: unset;
        left: 20%;
      }
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
      width: 60px;
      &:first-child {
        bottom: 15%;
        right: 10%;
        width: 40px;
      }
      &:last-child {
        top: 45%;
        left: 10%;
      }
    }
  }
`

export const JobsDecorationsTelework = styled.div`
  ${JobsDecorationsItem} {
    width: 150px;
    top: -5px;
    right: -50%;
    @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
      width: 100px;
      right: unset;
      left: 20%;
      top: 50%;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
      width: 80px;
      top: 45%;
      left: 0;
    }
  }
`

import { graphql, useStaticQuery } from 'gatsby'
import { getFluidImage } from 'helpers/getFluidImage'

export const fetchJobsImg = () => {
  const {
    avatar,
    human,
    startup,
    technology,
    telework,
  } = useStaticQuery(graphql`
    query {
      avatar: file(relativePath: { eq: "jobs/avatar.png" }) {
        base
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      human: allFile(
        sort: { fields: name, order: ASC }
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "jobs/human" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      startup: allFile(
        sort: { fields: name, order: ASC }
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "jobs/startup" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      technology: allFile(
        sort: { fields: name, order: ASC }
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "jobs/technology" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      telework: allFile(
        sort: { fields: name, order: ASC }
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "jobs/telework" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return {
    avatar: getFluidImage(avatar),
    human: getFluidImage(human, true),
    startup: getFluidImage(startup, true),
    technology: getFluidImage(technology, true),
    telework: getFluidImage(telework, true),
  }
}

import React, { FunctionComponent } from 'react'
import { ImageFilteredModel } from 'models/imageQuery.model'
import {
  JobsDecorationsHuman,
  JobsDecorationsItem,
  JobsDecorationsStartup,
  JobsDecorationsTech,
  JobsDecorationsTelework,
} from './JobsDecorations.style'
import Image from 'components/Image'
import { StyledComponent } from 'styled-components'

interface Props {
  images: ImageFilteredModel[]
  variant: string
}

interface VariantsDecorations {
  [name: string]: StyledComponent<any, any>
}

const variants: VariantsDecorations = {
  human: JobsDecorationsHuman,
  startup: JobsDecorationsStartup,
  technology: JobsDecorationsTech,
  telework: JobsDecorationsTelework,
}

const JobsDecorations: FunctionComponent<Props> = ({ images, variant }) => {
  const ElementParent = variants[variant]

  return (
    <ElementParent>
      {images.map((img: ImageFilteredModel, i: number) => (
        <JobsDecorationsItem key={i}>
          <Image fluid={img.fluid} alt={img.alt} />
        </JobsDecorationsItem>
      ))}
    </ElementParent>
  )
}

export default JobsDecorations

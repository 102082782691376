import styled from 'styled-components'
import { Col2, Container, MainText, PrimaryTitle, Row } from 'styles/Global'
import HeroBg from 'assets/images/jobs/heroBg.png'
import HeroBgMobile from 'assets/images/jobs/heroBgMobile.png'

export const JobsHero = styled.section`
  text-align: center;
  background: url('${HeroBg}') no-repeat center bottom;
  background-size: cover;

  ${Container} {
    position: relative;
    min-height: calc(100vh - 95px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${PrimaryTitle} {
    max-width: 60%;
    margin: 0 auto 20rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    background-image: url('${HeroBgMobile}');
    background-size: contain;
    ${Container} {
      align-items: flex-start;
    }
    ${PrimaryTitle} {
      max-width: 100%;
      margin: 6rem 0 0;
    }
  }
`

export const JobsRow = styled(Row)`
  align-items: flex-start;
  padding: 0;
  position: relative;
  margin-top: 8rem;
  ${PrimaryTitle} {
    margin-bottom: 1rem;
    span {
      color: ${({ theme }) => theme.Colors.red};
    }
  }
  ${MainText} {
    margin-bottom: 2.5rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    ${PrimaryTitle} {
      margin-bottom: 0.5rem;
    }
  }
`

export const JobsFixed = styled(Col2)`
  position: sticky;
  top: 150px;
  max-width: 25%;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
`

import React, { FunctionComponent } from 'react'
import { I18n } from '@lingui/react'
import { IndicatorScrollContainer } from './IndicatorScroll.style'
import { IconsName } from 'assets/svg/Icons.enum'

import Icons from 'components/atoms/Icons/Icons'

interface Props {
  text: string
  direction?: 'bottom' | 'up'
}

const IndicatorScroll: FunctionComponent<Props> = ({
  text,
  direction = 'bottom',
}) => {
  const goToTop = () => {
    if (direction === 'up') {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <IndicatorScrollContainer isUp={direction === 'up'} onClick={goToTop}>
      <p>
        {direction === 'up' ? (
          <I18n>{({ i18n }) => i18n._('indicator.top')}</I18n>
        ) : (
          text
        )}
      </p>
      <Icons
        name={
          direction === 'bottom' ? IconsName.ARROW_DOWN : IconsName.ARROW_UP
        }
      />
    </IndicatorScrollContainer>
  )
}

export default IndicatorScroll

import styled from 'styled-components'

export const JobsItem = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  &:first-child {
    min-height: calc(100vh - 8rem);
  }
  &:last-child {
    min-height: calc(100vh - 137px);
    button {
      margin-bottom: 2rem;
    }
  }
  ul {
    margin-top: 2rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: 4rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    min-height: unset;
    margin-top: 2.5rem;
    align-items: center;
    text-align: center;
    ul {
      display: none;
    }
  }
`

export const JobsMobileImage = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: block;
    width: 30%;
    margin: 4rem auto 0;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 50%;
    margin-top: 2.5rem;
  }
`

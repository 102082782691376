import React, { FunctionComponent } from 'react'
import { Trans } from '@lingui/macro'
import { fetchJobsImg } from 'hooks/fetch-jobs-img'
import {
  BackgroundBlack,
  Col2,
  Container,
  PrimaryTitle,
  TextRed,
} from 'styles/Global'
import { JobsFixed, JobsHero, JobsRow } from 'styles/pages/jobs.style'
import SEO from 'components/Seo'
import Layout from 'components/Layout'
import Image from 'components/Image'
import IndicatorScroll from 'components/atoms/IndicatorScroll/IndicatorScroll'
import Footer from 'components/molecules/Footer/Footer'
import Header from 'components/organisms/Header/Header'
import JobsSections from 'components/organisms/JobsSections/JobsSections'
import { I18n } from "@lingui/react"

interface Props {}

const JobsPage: FunctionComponent<Props> = () => {
  const allImages: any = fetchJobsImg()

  return (
    <Layout>
      <I18n>
        {({ i18n }) => (
          <SEO
            title="Jobs"
            description={i18n._("jobs.metaDescription")}
          />
        )}
      </I18n>
      <Header darkMode />
      <BackgroundBlack>
        <JobsHero>
          <Container>
            <PrimaryTitle>
              <Trans id="jobs.title">
                Allez plus loin avec une équipe <TextRed>plus proche</TextRed>.
              </Trans>
            </PrimaryTitle>
            <IndicatorScroll text="Scroll" />
          </Container>
        </JobsHero>
        <Container>
          <JobsRow>
            <Col2>
              <JobsSections images={allImages} />
            </Col2>
            <JobsFixed>
              <Image
                fluid={allImages.avatar.fluid}
                alt={allImages.avatar.alt}
              />
            </JobsFixed>
          </JobsRow>
        </Container>
        <Footer darkMode />
      </BackgroundBlack>
    </Layout>
  )
}

export default JobsPage
